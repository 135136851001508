import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { WebMessageAction } from '@utils/interfaces/web-messages/web-message-action.enum';
import { IVehicleDataGroup } from "@utils/interfaces/key-readout/vehicle-data-group.interface";
import { IKeyDataResponse } from "@utils/interfaces/key-readout/key-readout-response.interface";
import { Buffer } from 'buffer';
import * as pako from 'pako';

/**
 * This service handles the key data information
 */
@Injectable({
  providedIn: 'root',
})
export class VehicledataService {
  // contains the last edited key data element
  public replaySubject = new ReplaySubject<IVehicleDataGroup>(1);

  /**
   * Creates an instance of VehicledataService.
   *
   * @param http methods to perform HTTP requests
   * @param router provide navigation among the views
   * @param sessionStorageService handles the session storage communication
   * @param loadingIndicatorService contains the handling of the loading indicator
   */
  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private loadingIndicatorService: LoadingIndicatorService
  ) {
    // register web message handler for keydata requests
    window.chrome?.webview.addEventListener('message', (event: MessageEvent) => {
      this.receiveKeyReadoutStatusFromTrayApp(event);
    });
  }

  /**
   * Listen for web messages coming from the trayapp to send the language to webapp
   *
   * @param event message event with the web-message action and data
   */
  public receiveKeyReadoutStatusFromTrayApp(event: MessageEvent): Promise<void> {
    const { action, data } = JSON.parse(event.data);

    if (action == WebMessageAction.SendKeyDataStart) {

      // If the key data view is not yet open, navigate to that view
      if (this.router.url !== '/data') {
        this.router.navigate(['/data']);
      }
      this.loadingIndicatorService.show();

    } else if (action == WebMessageAction.SendKeyDataEnd) {

      // evaluate the value of the supplied data
      if(!data || this.isSendKeyDataFailure(data)){

        // resetting key data tab..
        this.processData(null);

      } else if (this.isSendKeyDataSuccess(data)) {

          // retrieving vehicle data from remote..
          return this.getVehicleData();

      } else if (data.length > 0) { // directly passed via web-message

        try {

          const buffer = Buffer.from(data, 'base64');
          const inflated = pako.inflate(buffer);
          const decoded = new TextDecoder().decode(inflated)
          this.processData(JSON.parse(decoded));

        } catch (err) {

          console.log('error while parsing data: ', err);
          return this.getVehicleData(); // fallback
        }

      }
    }
    return Promise.resolve();
  }

  /**
   * Get the key data from the '/da/keydata/details/latest' endpoint and pass it on to the vehicledata service subject
   */
  getVehicleData(): Promise<void> {
    // request header
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let options = {
      headers: headers,
    };
    return this.http
      .post<IKeyDataResponse>('/da/keydata/details/latest', null, options)
      .toPromise()
      .then(
        // Should the request be successful
        (res) => {
          this.processData(res.data);
        },
        // In case of error
        (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.processData(null)
          }
        }
      )
  }

  public processData(data): void {
    try {
      if (data && Object.keys(data).length > 0) {

        // Set vin for the key data page to know data is available
        const incomingVin = data.vehicleData.data.vin.value;
        this.sessionStorageService.setItem('vin', incomingVin.toString());

        // Add the info that key data is available to the session storage
        this.replaySubject.next({
          version: data.version, // Assuming 'version' is part of the response
          vehicleData: data.vehicleData,
          serviceKeyData: data.serviceKeyData,
          emissionData: data.emissionData
        });
        this.sessionStorageService.setItem('keyDataAvailable', 'true');

      } else { // invalid data

        this.replaySubject.next({
          version: null,
          vehicleData: null,
          serviceKeyData: null,
          emissionData: null
        });
        this.sessionStorageService.removeAllItems();
      }
    }
    catch (err) {
      console.log('error while processing data: ', err);
    } finally {
      this.loadingIndicatorService.hide();
    }
  }

  isSendKeyDataSuccess(value: string): boolean {
    return value && ['success', 'SendKeyDataSuccessful'].includes(value);
  }

  isSendKeyDataFailure(value: string): boolean {
    return value && ['failure', 'SendKeyDataFailed'].includes(value);
  }

  /**
   * complete the subject on destroy
   */
  ngOnDestroy() {
    this.replaySubject.complete();
  }
}
