import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Service contains the handling of the loading indicator
 */
@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService implements OnDestroy{
  // contains the last edited loading status
  private _loading = new BehaviorSubject<boolean>(false);
  // share the last edited loading indicator element
  public readonly loading$ = this._loading.asObservable()
  // started loading indicators
  private totalIndicators = 0;
  // completed loading indicators
  private completedIndicators = 0;

  /**
   * Should show the the loading indicator
   */
  show(): void {
    this._loading.next(true);
    this.totalIndicators++;
  }

  /**
   * Should hide the the loading indicator, when all started Loading indicator have been stopped again
   */
  hide(): void {
    this.completedIndicators++;
    if (this.completedIndicators >= this.totalIndicators) {
      this.totalIndicators = 0;
      this.completedIndicators = 0;
      this._loading.next(false);
    }
  }

  ngOnDestroy(): void {
    // unsubscribe to the loading state
    this._loading.complete();
  }
}
