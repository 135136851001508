import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LoadingIndicatorService } from '@services/loading-indicator/loading-indicator.service';
import { SessionStorageService } from '@services/session-storage/session-storage.service';
import { GetUserDataService } from '@services/get-user-data/get-user-data.service';
import { DefaultSettingsConstants } from '@utils/constants/default-settings.constants';

/**
 * This component checks if the user has admin access and restricts access
 */
@Injectable({
  providedIn: 'root',
})
export class AuthServiceGuard  {
  // available admin privileges
  flag: boolean;

  /**
   * Creates an instance of the AuthServiceGuard
   *
   * @param router provite navigation
   * @param loadingIndicatorService contains the handling of the loading indicator
   * @param userDataService retrieves user's market code and importer admin flag
   * @param sessionStorage handles the session storage communication
   * @param defaultSettings Recurring default values
   */
  constructor(
    private router: Router,
    public loadingIndicatorService: LoadingIndicatorService,
    private userDataService: GetUserDataService,
    private sessionStorage: SessionStorageService,
    private defaultSettings: DefaultSettingsConstants
  ) {}

  /**
   * Check if the user can access the restricted pages
   *
   * @param _next route information at a particular moment
   * @param _state route information at a moment in time
   * @returns a boolean if access is granted or not
   */
  public async canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Promise<boolean> {
    this.flag = await this.checkAdminPrivileges();
    if (this.flag == false) {
      this.router.navigateByUrl('/');
    }
    console.log('the reust on canActivate is ', this.flag);
    return this.flag;
  }

  /**
   * This function calls user-data-service to retrieve user info.
   *
   * @return true if the user has admin access and false if otherwise
   */
  public async checkAdminPrivileges(): Promise<boolean> {
    let userInfo = await this.userDataService.getUserData();
    this.sessionStorage.setItem(this.defaultSettings.localStorageMarketVariable, userInfo.marketCode);
    if (userInfo.importerAdminFlag === 1) {
      userInfo.importerAdminFlag = true;
    }
    if (userInfo.importerAdminFlag === 0) {
      userInfo.importerAdminFlag = false;
    }
    return userInfo.importerAdminFlag;
  }
}
